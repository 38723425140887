<template>
  <div>
    <v-toolbar flat>
      <template v-slot:extension>
        <v-tabs v-model="tabs" fixed-tabs icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#mobile-tabs-5-1">
            <v-icon>mdi-door</v-icon>Local
          </v-tab>

          <v-tab href="#mobile-tabs-5-2">
            <v-icon>mdi-layers</v-icon>Niveau
          </v-tab>

          <v-tab href="#mobile-tabs-5-3">
            <v-icon>mdi-calendar-clock</v-icon>Horaire
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs">
      <v-tab-item value="mobile-tabs-5-1">
        <v-card flat :loading="loading">
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in classrooms" :key="i">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-2">
        <v-card flat :loading="loading">
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in levels" :key="i">
                <v-list-item-content>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                  <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-tab-item>
      <v-tab-item value="mobile-tabs-5-3">
        <v-card flat :loading="loading">
          <v-list>
            <v-list-item-group color="primary">
              <v-list-item v-for="(item, i) in schedules" :key="i">
                <v-list-item-content>
                  <v-list-item-title v-text="item.day"></v-list-item-title>
                  <v-list-item-subtitle> {{item.startTime}} - {{item.endTime}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { APIServices } from "@/api/api";
export default {
  data() {
    return {
      loading: false,
      classrooms: null,
      levels: null,
      schedules: null,
      tabs: null,
      item: {
        name: "Frozen Yogurt",
        calories: 159,
        fat: 6.0,
        carbs: 24,
        protein: 4.0,
        sodium: 87,
        calcium: "14%",
        iron: "1%"
      },
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    };
  },
  mounted() {
    this.loadClassroom();
    this.loadLevels();
    this.loadSchedules();
  },

  methods: {
    loadSchedules() {
      console.log("loading schedules");
      this.loading = true;
      APIServices.get("/schedules")
        .then(response => {
          console.log(response);
          this.schedules = response.data;
          this.loading = false;
          console.log(this.classrooms);
        })
        .catch(e => {
          //this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    loadLevels() {
      console.log("loading levels");
      this.loading = true;
      APIServices.get("/levels")
        .then(response => {
          console.log(response);
          this.levels = response.data;
          this.loading = false;
          console.log(this.classrooms);
        })
        .catch(e => {
          //this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    },
    loadClassroom() {
      console.log("loading classrooms");
      this.loading = true;
      APIServices.get("/classrooms")
        .then(response => {
          console.log(response);
          this.classrooms = response.data;
          this.loading = false;
          console.log(this.classrooms);
        })
        .catch(e => {
          //this.errors.push(e);
          console.log(e);
          this.loading = false;
        });
    }
  }
};
</script>

<style></style>
